.create-parent {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.create-header {
    display: flex;
    flex-direction: row;
    align-items: start;
    padding: 10px 0 10px 0;
    height: 5vh;
    position: relative;
}

.create-back-button {
    color: white;
    cursor: pointer;
    width: 25px;
    height: 25px;
    position: relative;
    align-self: center;
    margin-left: 10px;
}

.create-title {
    margin: auto;
}

.create-title.title1, .create-title.title2 {
    margin-top: 20px;
}

.create-input-name {
    width: 100%;
}

.create-input-remember {
    margin-top: 10px;
    position: relative;
    align-self: start;
}

.create-input-container {
    display: flex;
    flex-direction: column;
    width: 90vw;
    align-items: center;
    text-align: start;
    margin-top: 20px;
    gap: 10px;

}

.create-divider-or-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100vw;
    margin-top: 20px;
}

.create-divider {
    width: 40%;
    height: 2px;
    border-radius: 1px;
    background-color: #35383F;
}

.create-divider-or {
    margin: 0 20px;
}

.create-body-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    position: relative;
    overflow-y: auto;
    flex-grow: 1;
}

.create-body-bottom {
    display: flex;
    flex-direction: row;
    width: 100vw;
    padding: 10px;
    position: relative;
    background: #181a20;
    align-items: center;
    justify-content: center;
    bottom: 0;
    height: max-content;
    border-top: 2px solid #282828;
}

.intro-button.sign-up-button {
    background-color: #6949FF;
    border-bottom: 2px solid #6949FF;
    padding: 15px 20px;
    font-size: 1.2em;
    font-family: DinBold, sans-serif;
    color: #fff;
    width: 90vw;
    border-radius: 30px;
}

.sso-button {
    background-color: #35383F;
    border: none;
    padding: 18px 20px;
    font-size: 1.2em;
    color: #fff;
    width: 90vw;
    border-radius: 10px;
    border-bottom: 2px solid #ffffff;
    position: relative;
    font-family: DinRegular, sans-serif;
}

.sso-button.facebook-button {
    margin-top: 5px;
    margin-bottom: 10px;
}