.sort-header-one {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    height: 40px;
}

.sort-header-one-title {
    font-size: 1.3em;
    font-weight: bold;
    margin: 0 10px;
}

.sort-header-one-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    color: #6949FF;
    gap: 10px;
}

.sort-header-one-icon {
    display: flex;
    flex-direction: row;
    width: 25px;
    height: 25px;
}