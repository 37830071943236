.trivia-scoreboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    font-size: 1.4em;
    margin: 0;
    width: 95vw;
    border-bottom: 1px solid #FFFFFF;
}

.trivia-scoreboard-header-close {
    width: 20px;
    height: 20px;
}