.join-header-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    font-family: DinRegular, sans-serif;
    padding: 0;
}

.join-header-left-icon {
    margin-left: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    align-self: center;
}

.join-header-name {
    font-size: 1.4em;
    font-weight: bold;
    color: white;
    margin-left: 10px;
    align-self: center;
    padding: 0;
}