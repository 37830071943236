.setting-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
}

.setting-item-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.setting-item-title {
    font-size: 20px;
}

.setting-item-img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
}