.MuiBottomNavigation-root {
    background: #181a20 !important;
    border-top: 1px solid #282c34 !important;
    color: #fff;
}

.Mui-selected svg {
    color: #6949FF !important;
}

.Mui-selected {
    color: #6949FF !important;
}

.MuiButtonBase-root  {
    color: #c0c0c0 !important;
}