.trivia-quiz-detail-card-one {
    display: flex;
    flex-direction: row;
    height: max-content;
    min-height: 100px;
    background-color: #1F222A;
    border-radius: 10px;
    margin: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    max-height: 100px;
}

.trivia-quiz-detail-card-one-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-size: 1em;
    font-weight: bold;
    text-align: start;
}

.trivia-quiz-detail-card-one-image {
    min-width: 30%;
    max-width: 100px;
    background-color: #61dafb;
    border-radius: 10px 0 0 10px;
    overflow: hidden;
    object-fit: cover;
}

.trivia-quiz-detail-card-one-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px;
    position: relative;
    align-items: start;
}

.trivia-quiz-detail-card-months-plays {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
    color: #c0c0c0;
    font-size: 0.8em;
    margin-top: 10px;
}

.trivia-quiz-detail-card-one-dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #c0c0c0;
    margin: 0 10px;
}

.trivia-quiz-detail-card-one-plays {
    font-weight: bold;
}

.trivia-quiz-detail-card-one-plays.EASY {
    color: #61dafb;
}

.trivia-quiz-detail-card-one-plays.MEDIUM {
    color: #FFD700;
}

.trivia-quiz-detail-card-one-plays.HARD {
    color: #FF6347;
}

.trivia-quiz-detail-card-one-plays.GOD {
    color: #8A2BE2;
}

.trivia-quiz-detail-card-one-author-parent {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
    color: #c0c0c0;
    font-size: 0.8em;
}

.trivia-quiz-detail-card-one-author-avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #61dafb;
    margin-right: 10px;
}