.search-bar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border-radius: 20px;
    font-size: 16px;
    text-align: start;
    font-family: DinRegular, sans-serif;
    position: relative;
    justify-items: center;
    flex-grow: 1;
}

.search-bar-input {
    display: flex;
    flex-grow: 1;
    padding: 15px 10px 15px 40px;
    border: none;
    border-radius: 10px;
    font-size: 1.2em;
    background: #35383F20;
    color: white;
    position: relative;
}

.search-bar-input:focus {
    border: 2px solid #6949FF;
}

.search-bar-icon {
    position: absolute;
    left: 20px;
    font-size: 20px;
    color: #35383F;
    width: 20px;
    height: 20px;
}