.collection-detail-container {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    height: 100vh;
}

.collection-detail-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
}

.collection-detail-image {
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin: 10px 20px;
    box-shadow: 0 0 10px 5px rgba(105, 73, 255, 0.2);
}

/* Make a 2x2 grid  on large screens */
@media screen and (min-width: 768px) {
    .collection-detail-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
}

@media screen and (min-width: 1024px) {
    .collection-detail-content {
        grid-template-columns: 1fr 1fr 1fr;
    }
}