.podium-user-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    font-family: DinBold, sans-serif;
    font-size: 1.2em;
    margin: 0;
}

.podium-user-container.user {
    background-color: #1F222A;
}

.podium-user-name {
    margin: 10px 0 0 0;
}

.podium-user-points {
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: #1F222A;
    padding: 5px 18px;
    border-radius: 20px;
    font-size: 0.9em;
}

.podium-user-image-container {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.podium-user-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}