@font-face {
    font-family: 'DinBold';
    src: url('../../assets/font/din_bold.otf') format('opentype');
}

@font-face {
    font-family: 'DinRegular';
    src: url('../../assets/font/din_regular.otf') format('opentype');
}

@font-face {
    font-family: 'OpenDyslexic3';
    src: url('../../assets/font/opendyslexic3.otf') format('opentype');
}

body {
    background: #181a20;
    font-family: DinRegular, sans-serif;
}

.intro-parent {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #181a20;
    color: #fff;
}

.intro-img {
    width: 80%;
    max-width: 400px;
    margin-top: 150px;
}

.intro-text {
    font-size: 1.8em;
    margin-top: 10px;
}

.intro-parent-top {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    height: 80%;
    font-family: DinRegular, sans-serif;
}

.intro-dot-indicators {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}

.intro-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #35383F;
    margin: 10px 0 10px 20px;
}

.intro-dot.active {
    background: #6949FF;
    width: 50px;
    border-radius: 10px;
}

.intro-parent-bottom {
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
}

.intro-button {
    background-color: #6949FF;
    border: none;
    padding: 15px 20px;
    font-size: 1em;
    color: #fff;
    border-radius: 30px;
    position: relative;
    cursor: pointer;
    max-width: 400px;
}

.existing-user-button {
    background-color: #35383F;
    border: none;
    color: #fff;
    border-radius: 20px;
    margin-top: 20px;
}
