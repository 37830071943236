.bool-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin: 15px;
    justify-content: center;
}

.bool-option {
    width: 45vw;
    height: 80vw;
    max-height: 300px;
    max-width: 200px;
    padding: 20px;
    border-radius: 20px;
    font-size: 1.2em;
    text-align: center;
    cursor: pointer;
    color: white;
    border: none;
}

.bool-option.one {
    background-color: #3498db;
    border-bottom: 5px solid #2980b9;
}

.bool-option.two {
    background-color: #e74c3c;
    border-bottom: 5px solid #c0392b;
}

.bool-option.three {
    background-color: #FF981F;
    border-bottom: 5px solid #FF7F0E;
}

.bool-option.four {
    background-color: #2ecc71;
    border-bottom: 5px solid #27ae60;
}