.top-three-users-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    font-family: DinBold, sans-serif;
    font-size: 1em;
    margin: 0;
}

.top-three-users-podium {
    display: flex;
    width: 320px;
    min-width: 320px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: max-content;
    margin: 0;
    padding: 0;
    max-width: 320px;
    align-self: center;
}

.top-three-users-stand {
    display: flex;
    flex-direction: column;
    height: 20vh;
    min-height: 20vh;
    margin: 0;
    padding: 0;
    background-image: url("../../assets/images/bg/podium1.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.podium-two {
    margin-bottom: -60px;
}

.podium-third {
    margin-bottom: -120px;
}
