.trivia-cover-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: relative;
    padding-top: 20px;
    font-family: DinRegular, sans-serif;
}

.homepage-header.trivia-cover-header {
    margin-left: 40px;
    display: flex;
    flex-direction: row;
}

.trivia-cover-search-bar {
    width: 100vw;
}

.trivia-cover-source-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    overflow-y: auto;
    padding-bottom: 20px;
    font-family: DinRegular, sans-serif;
}

.trivia-cover-source-list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 10px;
    width: calc(100% - 40px);
    margin: 10px;
    padding: 10px;
    border-radius: 20px;
    color: white;
    font-family: 'DinRegular', sans-serif;
    border: none;
    position: relative;
    cursor: pointer;
    flex-grow: 1;
}

.trivia-cover-source {
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 2px solid #6949FF;
    color: #6949FF;
    padding: 5px 15px;
    position: relative;
    cursor: pointer;
}

.trivia-cover-source.active {
    background: #6949FF;
    color: white;
}

.trivia-cover-source-content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: 90%;
    margin: 10px;
}

.trivia-cover-source-content-item {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 150px;
    border-radius: 10px;
    object-fit: cover;
}