.join-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: #6949FF;
    color: white;
    font-family: DinRegular, sans-serif;
    background-image: url("../../../assets/images/bg/scoreboard-bg.png");
}

.join-source-list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 10px;
    width: calc(100% - 40px);
    margin: 10px;
    padding: 10px;
    border-radius: 20px;
    color: white;
    font-family: 'DinRegular', sans-serif;
    border: none;
    position: relative;
    cursor: pointer;
}

.join-source {
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 2px solid white;
    color: white;
    padding: 5px 15px;
    position: relative;
    cursor: pointer;
}

.join-source.active {
    background: white;
    color: #6949FF;
}

.join-input {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    width: calc(100% - 40px);
    padding: 10px;
    font-weight: bold;
    text-align: center;
    flex-grow: 1;
    font-size: 4em;
    font-family: DinRegular, sans-serif;
}

.join-input::placeholder {
    color: white;
}

.join-button {
    background-color: white;
    border: none;
    font-size: 1.2em;
    color: #6949FF;
    border-radius: 35px;
    cursor: pointer;
    width: calc(100% - 40px);
    border-bottom: 5px solid #c0c0c0;
    margin: 40px 40px 10px 40px;
    padding: 10px;
    font-weight: bold;
    font-family: DinRegular, sans-serif;
}