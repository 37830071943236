.personal-info-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100vh;
    padding: 10px;
}

.personal-info-img-container {
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    position: relative;
    align-self: center;
}

.personal-info-image {
    border-radius: 50%;
    width: 140px;
    height: 140px;
}

.personal-info-edit {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #6949FF;
    padding: 5px;
    border-radius: 5px;
}

.personal-info-label {
    font-size: 1.2em;
    display: flex;
    flex-direction: column;
    text-align: start;
    margin: 10px 20px;
}

.personal-info-input {
    background-color: transparent;
    color: white;
    border: none;
    border-bottom: 1px solid #6949FF;
    margin-top: 10px;
    padding: 5px;
    font-size: 1.2em;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}