.quiz-details-container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0;
    height: 100vh;
}
.quiz-details-container.iphone {
    height: 90vh;
}

.quiz-details-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    min-height: 60px;
    position: relative;
}

.quiz-details-content {
    display: flex;
    flex-direction: column;
    font-family: 'DinRegular', sans-serif;
    position: relative;
    overflow-y: auto;
    flex-grow: 1;
    margin-bottom: 60px;
}

.quiz-details-content::-webkit-scrollbar {
    display: none;
}

.quiz-details-image {
    height: 25vh;
    width: calc(100% - 40px);
    background: #6949FF;
    border-radius: 10px;
    object-fit: cover;
    margin: 10px;
    align-self: center;
}

.quiz-details-title {
    font-size: 1.5em;
    margin: 5px 0 20px 20px;
    text-align: start;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

}

.quiz-details-divider {
    width: calc(100% - 40px);
    height: 1px;
    background: #35383F;
    margin: 10px 20px 10px 20px;
}

.quiz-details-quiz-stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 20px;
    font-size: 1.2em;
}

.quiz-details-quiz-separator {
    width: 1px;
    height: 50px;
    background: #35383F;
    margin: 0 10px;
}

.quiz-details-quiz-stats-item p {
    margin: 0;
}

.quiz-details-author-left {
    display: flex;
    flex-direction: row;
    color: #fff;
    font-family: DinBold, sans-serif;
    padding: 0;
}

.quiz-details-author-parent {
    display: flex;
    flex-direction: row;
    margin: 5px 20px;
    align-items: center;
    justify-content: space-between;
}

.quiz-details-author-avatar {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background: #6949FF;
    margin: 5px;
    object-fit: cover;
}

.quiz-details-author-text-parent {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    text-align: start;
    gap: 5px;
    justify-content: center;
}

.quiz-details-author {
    font-size: 1.2em;
    font-weight: bold;
}

.quiz-details-author-username {
    font-size: 0.8em;
    font-weight: normal;
    font-family: DinRegular, sans-serif;
}

.quiz-details-author-follow {
    font-size: 1em;
    margin: 5px;
    background: #6949FF;
    color: white;
    padding: 5px 15px;
    border-radius: 18px;
    height: max-content;
    border: 2px solid #6949FF;
}

.quiz-details-author-follow.following {
    background-color: transparent;
    color: #6949FF;
}

.quiz-details-description {
    font-size: 1em;
    text-align: start;
    margin: 0 20px;
}

.quiz-details-description.title {
    font-size: 1.4em;
}

.quiz-details-description.content {
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 1.2em;
}

.quiz-details-tags.title {
    font-size: 1.4em;
    margin: 0 20px;
    width: max-content;
}

.quiz-details-difficulty {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    gap: 10px;
    align-items: center;
    font-size: 1.4em;
    padding: 0;
}

.quiz-details-difficulty-value {
    font-size: 0.7em;
    background: #6949FF;
    color: white;
    padding: 5px 15px;
    border-radius: 18px;
    height: max-content;
    width: max-content;
    margin: 0;
}

.quiz-details-difficulty-value.easy {
    background: #007f00;
}

.quiz-details-difficulty-value.medium {
    background: #ccac00;
}

.quiz-details-difficulty-value.hard {
    background: #cc0000;
}

.quiz-details-difficulty-value.god {
    background: #330000;
}

.quiz-details-difficulty-title {
    font-size: 1em;
    margin: 0;
}

.quiz-details-tags-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.quiz-details-tags {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 70vw;
    /* Any item that doesn't fit on the line should wrap to the next line */
    flex-wrap: nowrap;
}

.quiz-details-tag {
    font-size: 1em;
    background: #6949FF;
    color: white;
    padding: 2px 10px;
    border-radius: 18px;
    height: max-content;
}

.quiz-details-footer {
    display: flex;
    flex-direction: row;
    background-color: #181a20;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 10px 0;
    overflow: hidden;
    justify-content: space-between;
}

.intro-button.play {
    min-height: 45px;
    margin-left: 20px;
    font-size: 0.8em;
    text-align: center;
    padding: 0 10px;
    width: 50%;
}

.intro-button.play.play-solo {
    background: #35383F;
    border-bottom: 3px solid #1F222A;
}

.intro-button.play.play-buddy {
    margin-right: 20px;
    border-bottom: 3px solid #4933b2;
}

@media screen and (max-height: 800px) {
    .quiz-details-image {
        height: 20vh;
    }

    .quiz-details-title {
        font-size: 1.2em;
    }

    .quiz-details-author-avatar {
        height: 40px;
        width: 40px;
    }

    .quiz-details-author {
        font-size: 1em;
    }

    .quiz-details-author-username {
        font-size: 0.6em;
    }

    .quiz-details-description.title {
        font-size: 1.2em;
    }

    .quiz-details-description.content {
        font-size: 1em;
    }

    .quiz-details-tags.title {
        font-size: 1.2em;
    }

    .quiz-details-tag {
        font-size: 0.8em;
    }

    .intro-button.play {
        height: 30px;
        font-size: 0.6em;
    }

    .quiz-details-author-follow {
        font-size: 0.7em;
    }
}

