.homepage-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    z-index: 2;
}

.homepage-header-left {
    display: flex;
    flex-direction: row;
    color: #fff;
    font-family: DinBold, sans-serif;
    padding: 0;
}

.homepage-app-name {
    font-size: 1.4em;
    align-self: center;
    font-family: DinRegular, sans-serif;
    margin-left: 10px;
}

.homepage-clear-logo {
    width: 30px;
    height: 30px;
    max-width: 400px;
    align-self: center;
}

.homepage-header-right {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-right: 10px;
}

.homepage-header-icon {
    width: 25px;
    height: 25px;
    max-width: 400px;
    align-self: center;
}