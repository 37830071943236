.trivia-session-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    font-size: 1.4em;
    margin: 0;
    width: 92vw;
}

.trivia-session-header-menu {
    width: 25px;
    height: 25px;
}