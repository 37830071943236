.question-type-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    margin: 15px;
    max-width: 100vw;
    padding: 0 40px 0 0;
    overflow-y: auto;
    height: 100%;
}

.question-type-grid::-webkit-scrollbar {
    display: none;
}

.question-type-grid-item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 10px;
    background-color: #262A35;
    border: 2px solid #35383F;
    color: white;
    cursor: pointer;
    margin: 10px;
    transition: background-color 0.3s;
}