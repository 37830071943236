.trivia-card-two-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 250px;
    width: 220px;
    min-width: 180px;
    max-height: 200px;
    margin: 10px;
    border-radius: 20px;
    background: #282c34;
    color: white;
    font-family: 'DinRegular', sans-serif;
    border: none;
    border-bottom: 4px solid #4933b2;
    position: relative;
    cursor: pointer;
}

.trivia-card-two-image {
    height: 60%;
    width: 100%;
    background: #6949FF;
    border-radius: 10px;
    object-fit: cover;
}

.trivia-card-two-quiz-count {
    position: absolute;
    background: #6949FF;
    padding: 5px 10px;
    right: 5px;
    border-radius: 5px;
    font-size: 0.8em;
    top: 35%;
}

.trivia-card-two-title {
    font-size: 1em;
    margin: 5px;
    line-height: 1;
    padding-bottom: 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    text-align: start;
}


.trivia-card-two-author-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px;
    gap: 5px;
}

.trivia-card-two-author-avatar {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #6949FF;
    margin: 5px 0 5px 5px;
}

@media screen and (max-height: 800px) {
    .trivia-card-two-container {
        width: 160px;
        height: 180px;
    }

    .trivia-card-two-title {
        font-size: 0.7em;
    }

    .trivia-card-two-author-parent {
        font-size: 0.7em;
    }
}