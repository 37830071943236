.home-fragment-parent {
    display: flex;
    flex-direction: column;
    color: white;
    padding: 10px 0;
    overflow-y: hidden;
}

.home-fragment-header {
    position: fixed;
    display: flex;
    flex-direction: row;
    height: 60px;
    width: 100%;
}

.home-fragment-content {
    position: relative;
    flex-grow: 1;
    height: calc(100vh - 60px);
    overflow-y: auto;
    margin-top: 60px;
}

.home-fragment-content::-webkit-scrollbar {
    display: none;
}

.home-fragment-parent::-webkit-scrollbar {
    display: none;
}

.hidden {
    display: none;
}

.home-fragment-horizontal-list {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    height: max-content;
    margin: 0;
    align-items: center;
}

.home-fragment-horizontal-list.quiz {
    min-height: 220px;
}

.home-fragment-horizontal-list.avatar {
    min-height: 120px;
}

.home-fragment-horizontal-list.collection {
    min-height: 120px;
}

.home-fragment-horizontal-list::-webkit-scrollbar {
    display: none;
}