.trivia-card-one-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    min-height: 180px;
    margin: 10px;
    border-radius: 20px;
    background-color: #6949FF;
    border-bottom: 5px solid #4933b2;
    background-image: url("https://www.transparenttextures.com/patterns/gplay.png");
}

.trivia-card-one-title {
    font-size: 140%;
    margin: 10px 20px;
    width: 60%;
    text-align: start;
    font-weight: bold;
    line-height: 1.8;
}

.trivia-card-one-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 20px;
    background: white;
    color: #6949FF;
    border-radius: 15px;
    padding: 5px 15px;
    font-size: 1em;
}

@media screen and (max-height: 800px) {
    .trivia-card-one-container {
        min-height: 120px;
    }

    .trivia-card-one-title {
        font-size: 100%;
    }

    .trivia-card-one-action {
        font-size: 0.7em;
    }
}