.body {
    margin: 0;
    padding: 0;
    background: #1F222A;
}

.drag-list-options {
    display: flex;
    flex-direction: column;
    margin: 15px;
    justify-content: center;
}

.drag-list-option {
    width: 90vw;
    height: max-content;
    padding: 0 10px;
    margin: 10px 20px;
    max-width: 400px;
    border-radius: 10px;
    font-size: 1.2em;
    text-align: center;
    color: white;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.drag-list-option.one {
    background-color: #3498db;
    border-bottom: 5px solid #2980b9;
}

.drag-list-option.two {
    background-color: #e74c3c;
    border-bottom: 5px solid #c0392b;
}

.drag-list-option.three {
    background-color: #FF981F;
    border-bottom: 5px solid #FF7F0E;
}

.drag-list-option.four {
    background-color: #2ecc71;
    border-bottom: 5px solid #27ae60;
}