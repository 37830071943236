.scoreboard-user-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    font-family: DinBold, sans-serif;
    font-size: 1.2em;
}

.scoreboard-user-ranking {
    margin-right: 10px;
}

.scoreboard-user-container.user {
    background-color: #1F222A;
}

/*.scoreboard-user-container:nth-child(odd) {
    background-color: #1F222A;
}*/

.scoreboard-user-image-container {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.scoreboard-user-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}