.viewall-content.collection-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
    margin-top: 60px;
}

.trivia-collection-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
}

@media (min-width: 576px) {
    .viewall-content.collection-grid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (min-width: 768px) {
    /** 3x3 Grid **/
    .viewall-content.collection-grid {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (min-width: 950px) {
    /** 4x4 Grid **/
    .viewall-content.collection-grid {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}

@media (min-width: 1200px) {
    /** 5x5 Grid **/
    .viewall-content.collection-grid {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

@media (min-width: 1400px) {
    /** 6x6 Grid **/
    .viewall-content.collection-grid {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}