.profile-source-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: calc(100vh - 55px);
    position: relative;
    font-family: DinRegular, sans-serif;
}

.profile-header-image {
    width: calc(100% - 40px);
    height: 150px;
    max-height: 30vh;
    object-fit: cover;
    border-radius: 20px;
    margin: 10px 20px;
    align-content: center;
    border-bottom: 5px solid #35383F;
    background-color: #6949FF;
    background-image: url("https://www.transparenttextures.com/patterns/gplay.png");
}

.profile-stats-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% / 3);
}

.profile-source-divider {
    width: calc(100% - 40px);
    height: 1px;
    background: #35383F;
    margin: 5px 20px;
}

.profile-vertical-divider {
    width: 1px;
    height: 50px;
    background-color: #35383F;
    align-self: center;
    margin: 0 10px;
}

.profile-stats {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0 20px;
}

.profile-stats-value {
    font-size: 1.5em;
    font-family: DinBold, sans-serif;
    margin: 0;
}

.profile-stats-title {
    font-size: 1em;
    font-family: DinRegular, sans-serif;
    margin: 0;
}

.profile-source-top {
    display: flex;
    flex-direction: column;
}

.profile-source-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex-grow: 1;
    position: relative;
}

.profile-source-list::-webkit-scrollbar {
    display: none;
}

@media screen and (max-height: 800px) {
    .profile-header-image {
        height: 100px;
    }

    .profile-stats-value {
        font-size: 1em;
    }

    .profile-stats-title {
        font-size: 0.8em;
    }

    .profile-vertical-divider {
        height: 25px;
    }
}