.bottom-bar-container {
    display: flex;
    position: relative;
    background-color: #181a20;
    height: 40px;
    justify-content: space-around;
    padding: 10px;
}

.bottom-bar-item {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    gap: 10px;
}

.bottom-bar-item.active {
    background-color: #6949FF;
    border-radius: 30px;
    padding: 15px 20px;
    justify-content: space-between;
}

.bottom-bar-icon {
    width: 20px;
    height: 20px;
}

.bottom-bar-title {
    font-size: 1em;
}

.bottom-bar-title.hidden {
    display: none;
}