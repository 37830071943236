.trivia-header-one-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: max-content;
    margin: 5px 20px;
    border-radius: 20px;
}

.trivia-header-one-title {
    font-size: 24px;
    margin: 10px 0;
    width: 60%;
    text-align: start;
    font-family: DinBold, sans-serif;
    line-height: 1.5;
}

.trivia-header-one-title.question {
    font-family: DinRegular, sans-serif;
}


.trivia-header-one-action {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    color: #6949FF;
    border-radius: 15px;
    padding: 5px 15px;
}

@media screen and (max-height: 800px) {

    .trivia-header-one-title {
        font-size: 20px;
    }

    .trivia-header-one-action {
        font-size: 14px;
    }
}