.viewall-parent {
    display: flex;
    flex-direction: column;
    color: white;
    position: relative;
    overflow-y: hidden;
}

.viewall-header {
    position: fixed;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    padding: 0 10px;
    height: 60px;
    width: 100%;
}

.viewall-content {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
    height: calc(100vh - 60px);
    overflow-y: auto;
    margin-top: 60px;
}

.viewall-content.catgories {
    /* Make into a 2 column grid */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
}

.viewall-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.trivia-collection-container.viewall-collection-body {
    width: 45vw;
}

@media (min-width: 576px) {
    .trivia-collection-container.viewall-collection-body {
        width: 30vw;
    }
}

@media (min-width: 768px) {
    .trivia-collection-container.viewall-collection-body {
        width: 20vw;
    }
}

@media (min-width: 950px) {
    .trivia-collection-container.viewall-collection-body {
        width: 15vw;
    }
}

@media (min-width: 1200px) {
    .trivia-collection-container.viewall-collection-body {
        width: 10vw;
    }
}