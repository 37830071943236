.mcq-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin: 15px;
    justify-content: center;
    position: relative;
}

.mcq-option {
    width: 45vw;
    height: 40vw;
    max-height: 180px;
    max-width: 180px;
    padding: 20px;
    border-radius: 20px;
    font-size: 1.2em;
    text-align: center;
    cursor: pointer;
    color: white;
    border: none;
    position: relative;
    opacity: 1;
}

.mcq-option.disabled {
    cursor: not-allowed;
    background-color: #2c3e50;
    opacity: 0.8;
}

.mcq-option.input {
    color: white;
    max-width: 120px;
    max-height: 100px;
}

.mcq-option.input::-webkit-scrollbar {
    display: none;
}

.mcq-option-result-icon {
    width: 15px !important;
    height: 15px !important;
    background-color: white;
    color: black;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 5px;
}

.mcq-option.input::-webkit-input-placeholder {
    color: white;
}

.mcq-option.one {
    background-color: #3498db;
    border-bottom: 5px solid #2980b9;
}

.mcq-option.two {
    background-color: #e74c3c;
    border-bottom: 5px solid #c0392b;
}

.mcq-option.three {
    background-color: #FF981F;
    border-bottom: 5px solid #FF7F0E;
}

.mcq-option.four {
    background-color: #2ecc71;
    border-bottom: 5px solid #27ae60;
}

.mcq-option.five {
    background-color: #7A5548;
    border-bottom: 5px solid #5D4037;
}

.mcq-option.six {
    background-color: #607D8A;
    border-bottom: 5px solid #455A64;
}

.mcq-option.seven {
    background-color: #9D28AC;
    border-bottom: 5px solid #7B1FA2;
}

.mcq-option.eight {
    background-color: #009689;
    border-bottom: 5px solid #00796B;
}

.mcq-option.correct.result {
    background-color: #2ecc71;
    border-bottom: 5px solid #27ae60;
}

.mcq-option.wrong.result {
    background-color: #e74c3c;
    border-bottom: 5px solid #c0392b;
}