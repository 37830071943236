.trivia-response-alert {
    height: 180px;
    color: white;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    border-radius: 0 0 20px 20px;
    background-image: url("../../assets/images/bg/quiz-alert-bg.png");
}

.trivia-response-content {
    position: relative;
    width: 100vw;
}

.trivia-response-alert.hidden {
    display: none;
}

.trivia-response-alert.correct {
    background-color: #12D18E;
}

.trivia-response-alert.incorrect {
    background-color: #F75555;
}

.trivia-response-alert-description {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    background: white;
    width: max-content;
    border-radius: 20px;
    padding: 5px 15px;
    margin: 0 auto;
    align-self: center;
}

.trivia-response-alert-description.correct {
    color: #12D18E;
}

.trivia-response-alert-description.incorrect {
    color: #F75555;
}