.profile-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 20px);
    padding: 0 10px;
    height: 60px;
}

.profile-name {
    font-size: 1.8em;
    font-family: DinRegular, sans-serif;
    margin: 0 10px;
}

.profile-header-left {
    display: flex;
    flex-direction: row;
    color: #fff;
    font-family: DinBold, sans-serif;
    padding: 0;
    align-items: center;
}

.profile-left-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: none;
}

.profile-header-right {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.profile-header-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: none;
}
