.MuiFormControl-root {
    color: white;
    border-color: white;
    background-color: #1c1c1e;
    border-radius: 10%;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 35px;
}

#demo-simple-select {
    color: white !important;
}

.MuiSelect-iconOutlined {
    color: white !important;
}

.MuiMenuItem-root {
    color: black !important;
    font-family: DinRegular, sans-serif !important;
}


@media screen and (min-width: 240px) and (max-width: 350px) {
    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        height: 30px;
    }

    .css-1agwao2-MuiFormControl-root {
        margin-bottom: 40px !important;
    }
}