.body {
    margin: 0;
    padding: 0;
}

.trivia-session-active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    flex-grow: 1;
    background: #1F222A;
}

.trivia-session-active.hidden {
    display: none;
}

.trivia-session-inactive.hidden {
    display: none;
}

.trivia-session-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background: #1F222A;
}

.trivia-session-container.iphone {
    height: 90vh;
}

.trivia-session-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: auto;
    position: relative;
}

.trivia-session-content::-webkit-scrollbar {
    display: none;
}

.trivia-session-progress {
    margin: 0 20px 20px 20px;
    width: calc(100% - 40px);
    max-width: 400px;
    align-self: center;
}

.trivia-session-image {
    width: 380px;
    max-width: 400px;
    height: 250px;
    object-fit: cover;
    border-radius: 20px;
    margin: 20px;
}

.trivia-session-question {
    margin: 20px;
    font-size: 1.4em;
    text-align: center;
}

.trivia-session-footer {
    display: flex;
    justify-content: center;
    height: 80px;
}

.trivia-session-footer-button {
    font-size: 1.2em;
    cursor: pointer;
    color: white;
    border: none;
    width: 100vw;
    max-width: 400px;
    height: max-content;
    padding: 15px 20px;
    background: #6949FF;
    border-radius: 25px;
    align-self: center;
    margin: 10px 20px;
    border-bottom: 5px solid #4C2BFF;
}