.trivia-collection-container {
    display: flex;
    flex-direction: column;
    height: 100px;
    min-width: 180px;
    margin: 10px;
    min-height: 140px;
    background-color: #282828;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    border-bottom: 4px solid #4933b2;
}

.trivia-collection-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
}

.trivia-collection-darken {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
}

.trivia-collection-title {
    position: absolute;
    left: 10px;
    bottom: 10px;
}