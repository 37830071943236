.trivia-scoreboard-container {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    background-color: #6949FF;
    height: 100vh;
    background-image: url("../../assets/images/bg/scoreboard-bg.png");
    position: relative;
    font-family: DinRegular, sans-serif;
}


.trivia-scoreboard-content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
    height: 100vh;
    flex-grow: 1;
}

.trivia-scoreboard-content::-webkit-scrollbar {
    display: none;
}

.trivia-scoreboard-footer {
    display: flex;
    flex-direction: row;
    height: 10vh;
    width: 100vw;
    position: relative;
    margin: 0;
    align-items: center;
    overflow: hidden;
}

.trivia-scoreboard-footer-button {
    font-size: 1.2em;
    cursor: pointer;
    color: #6949FF;
    border: none;
    width: 100vw;
    height: max-content;
    padding: 15px 20px;
    border-radius: 25px;
    align-self: center;
    margin: 10px 20px;
    background-color: white;
    border-bottom: 5px solid #c0c0c0;
    font-family: DinRegular, sans-serif;
}