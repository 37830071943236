.trivia-create-session-question {
    width: calc(100% - 100px);
    max-width: 400px;
    padding: 40px 20px;
    border-radius: 10px;
    border: 2px solid #35383F;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    background: transparent;
    color: white;
}

.trivia-create-session-time-group {
    display: flex;
    flex-direction: row;
    max-width: 400px;
    align-items: center;
    margin: 20px 10px;
    gap: 10px;
}

.trivia-create-session-time-group-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background: #6949FF;
    color: white;
    font-size: 18px;
    padding: 5px 10px;
}

.trivia-create-session-time-group-item-blank {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}