.homepage-left-icon.trivia-header-three {
    width: 20px;
    height: 20px;
    max-width: 400px;
    align-self: center;
}

.homepage-app-name.trivia-header-three-title {
    font-size: 20px;
    align-self: center;
    font-family: DinRegular, sans-serif;
    margin-left: 10px;
}

.homepage-header.trivia-header-three-container{
    padding: 0 10px;
}