body {
    background-color: #181a20;
    font-family: DinRegular, sans-serif;
    color: white;
    text-align: center;
    max-width: 100vw;
}

.homepage-parent {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #181a20;
    color: #fff;
    overflow: hidden;
}

.homepage-header {
    position: relative;
    height: 150px;
}

.homepage-body {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 70px;
    height: calc(100vh - 60px);
    overflow-y: auto;
}

.homepage-body::-webkit-scrollbar {
    display: none;
}

.homepage-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.homepage-bottom-bar {
    display: flex;
    flex-direction: column;
    width: 100vw;
    font-family: DinRegular, sans-serif;
}