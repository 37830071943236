.trivia-avatar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: max-content;
    min-width: 100px;
    max-height: max-content;
    border-radius: 20px;
    font-family: DinRegular, sans-serif;
    position: relative;
    margin-left: 10px;
    cursor: pointer;
}

.trivia-avatar-image {
    height: 70px;
    width: 70px;
    background: #6949FF;
    border-radius: 50%;
    object-fit: cover;
}

.trivia-avatar-username {
    font-size: 1.2em;
    margin-top: 5px;
}

@media screen and (max-height: 800px) {
    .trivia-avatar-container {
        width: 100px;
    }

    .trivia-avatar-image {
        height: 70px;
        width: 70px;
    }

    .trivia-avatar-username {
        font-size: 1em;
    }
}