.playground-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 20px);
    background-color: #282828;
    padding: 10px;
}

.playground-header {
    display: flex;
    flex-direction: row;
    padding: 10px;
    background: #6949FF;
}

.playground-content {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #61dafb;
    color: white;
    flex-grow: 1;
    overflow-y: auto;
}

.playground-footer {
    display: flex;
    flex-direction: row;
    padding: 10px;
    background: #6949FF;
}

.playground-content-top {
    display: flex;
    flex-direction: row;
    padding: 10px;
    background: #1F222A;
}

.playground-content-middle {
    display: flex;
    flex-direction: row;
    padding: 10px;
    background: #2ecc71;
    flex-grow: 1;
}

.playground-content-bottom {
    display: flex;
    flex-direction: row;
    padding: 10px;
    background: #1F222A;
}

