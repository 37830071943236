.create-quiz-container {
    display: flex;
    flex-direction: column;
    position: relative;
    font-family: DinRegular, sans-serif;
    overflow: hidden;
}

.create-quiz-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.create-quiz-content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: center;
}

.create-quiz-content::-webkit-scrollbar {
    display: none;
}

.create-quiz-image {
    width: calc(100% - 40px);
    height: 200px;
    object-fit: cover;
    margin: 10px 20px;
    border-radius: 20px;
    max-width: 400px;
    background: #35383F;
    border: 2px solid #6949FF;
}

.create-quiz-inputs {
    margin: 10px 20px;
    padding: 10px;
    border-radius: 20px;
    font-size: 16px;
    max-width: 400px;
    text-align: start;
}

.create-quiz-input {
    width: calc(100% - 40px);
    margin: 10px 10px;
    padding: 10px 10px;
    border-radius: 5px;
    font-size: 16px;
    border: none;
}

.create-quiz-input.description {
    height: 60px !important;
}

.create-quiz-spinner {
    width: calc(100vw - 80px);
    background: #1F222A;
    max-width: 400px;
}

.create-quiz-label {
    font-size: 16px;
    margin: 10px 10px;
    resize: vertical;
}

.create-quiz-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100vw;
    max-width: 400px;
    align-self: center;
    font-size: 16px;
    position: relative;
}

.create-quiz-sheet-title {
    margin: 0;
}

.create-quiz-button {
    width: 50vw;
    margin: 10px 20px;
    border-radius: 25px;
    font-size: 16px;
    background: #35383F;
    color: white;
    border: none;
    border-bottom: 5px solid #1F2023;
    padding: 10px 20px;
    font-family: DinRegular, sans-serif;
    font-weight: bold;
}

.create-quiz-button.add {
    background: #6949FF;
    border-bottom: 5px solid #4C36EB;
}

.react-modal-sheet-container {
    background: #1F222A !important;
}