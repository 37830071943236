.search-screen-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: DinRegular, sans-serif;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.search-screen-searchbar-container {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    position: relative;
}

.search-screen-pill-header {
    display: flex;
    flex-direction: row;
    position: relative;
}

.search-screen-back-button {
    width: 30px;
    height: 30px;
    align-self: center;
}

.search-screen-content-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex-grow: 1;
    position: relative;
}